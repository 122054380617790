import React from "react";


export default class Refund extends React.Component {

    render() {
      return (
        <div className="bg-light">

        <main className=" bg-light ">
            {/* Container START */}

         
        <div className="container mt-4 mb-5">
        <div className="row g-4  mt-5 ">

                  
        <div className="col-md-8 col-8 col-lg-5 vstack ">
          
        <h1>Refund Policy</h1>

<p>We at BHealth are committed to providing you with the best possible online therapy experience. 
However, we understand that there may be instances where a refund is necessary. 
Please read our refund policy carefully to understand your rights and how to proceed if you require a refund.</p>

<h2>1. Refund Eligibility</h2>

<p>Refunds may be issued under the following circumstances:</p>
<ul>
    <li>If you cancel your session at least 24 hours before the scheduled time, you are eligible for a full refund.</li>
    <li>If the therapist cancels the session and a reschedule is not possible, you are eligible for a full refund.</li>
    <li>If you experience technical difficulties on our platform that prevent the session from taking place, and our support team cannot resolve the issue, you may be eligible for a refund.</li>
</ul>

<h2>2. Non-Refundable Situations</h2>

<p>Refunds will not be issued in the following cases:</p>
<ul>
    <li>Failure to attend a session without prior notice (No-Show).</li>
    <li>If a session is canceled less than 24 hours before the scheduled time.</li>
    <li>If the session is completed but you are unsatisfied with the therapist's advice or methods.</li>
</ul>

<h2>3. Requesting a Refund</h2>

<p>To request a refund, please follow these steps:</p>
<ul>
    <li>Email us at <a href="mailto:support@BHealththerapy.com">support@yourwebsite.com</a> with your order number, the reason for the refund request, and any supporting documentation.</li>
    <li>Our team will review your request within 5-7 business days and notify you of the outcome.</li>
    <li>If approved, the refund will be processed back to your original method of payment within 10 business days.</li>
</ul>

<h2>4. Contact Us</h2>

<p>If you have any questions about our refund policy, please contact us at:</p>
<p>Email: <a href="mailto:support@BHealththerapy.com">support@yourwebsite.com</a><br />
</p>

<p>We value your business and are here to help with any concerns you may have.</p>

<br/>
        <br/>
        <br/>
        <br/>
        <br/>

</div>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>

        </div>
        </div>
        </main>
        </div>

    )
    }
  };