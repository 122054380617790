import React from "react";

import {  auth, db, logout } from "../firebase";

import {  Modal, ModalBody, Button, ModalHeader } from 'react-bootstrap';

import NotFound from "../assets/notfound.png"

import 'react-day-picker/dist/style.css';
import { format } from 'date-fns';


import toast from 'react-hot-toast';
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import Chat from "./Chat";

class AccessAdmin extends React.Component {

  constructor(props) {
    super(props) 
    this.state = {
        transactions:[],
        therapists:[],
        name:"",
        description:"",
        retireMethod: "paypal",
        balance:0,
        retireAmount:0,
        retireAccount: null,
        patientsList:[],
        tab:2,
    }
  } 
  componentDidMount() {
    
    this.getUser()

    setTimeout(() => {
        this.getUser()
    }, 1000);
  }



  async getUser() {

    if(auth?.currentUser?.email === "adminaccount@bhealththerapy.com") {

          this.setState({isTherapist: true})
          this.getTransactions()
          this.getUsers()
          this.getTherapists() 
          this.getWithdraws();
     
    } 
    else {
      this.setState({isTherapist: false})

  }
};

addTherapist(newItem) {
  if(newItem.img !== undefined){ 
  this.setState({ therapists: [...this.state.therapists, newItem] })}
}

openSesion(item) {
  this.setState({modalSesion:true,sesionData:item})

}
addTransaction(newItem) {
  this.setState({ transactions: [...this.state.transactions, newItem] })
}
addUser(newItem) {
    this.setState({ users: [...this.state.users, newItem] })
  }

  addWithdraws(newItem) {
    this.setState({ withdraws: [...this.state.withdraws, newItem] })
  }
  

async getSesions() {
    this.setState({sesions:[]});
    await db.collection("withdraws").get()
    .then(res => {
        res.forEach(doc => {

            let newItem = doc.data();
            this.addS(newItem)

        });
    })
    .catch(err => { console.error(err) });


} 

async getTherapists() {
    this.setState({therapists:[]});
    await db.collection("therapists").get()
    .then(res => {
        res.forEach(doc => {

            let newItem = doc.data();
            newItem.email = doc.id;
            this.addTherapist(newItem)

        });
    })
    .catch(err => { console.error(err) });


} 

async getTransactions() {
  this.setState({transactions:[]});
  await db.collection("transactions").get()
  .then(res => {
      res.forEach(doc => {

          let newItem = doc.data();
          newItem.email = doc.id;
          this.addTransaction(newItem)

      });
  })
  .catch(err => { console.error(err) });
}


async getWithdraws() {
    this.setState({withdraws:[]});
    await db.collection("withdraws").get()
    .then(res => {
        res.forEach(doc => {
  
            let newItem = doc.data();
            newItem.email = doc.id;
            this.addTransaction(newItem)
  
        });
    })
    .catch(err => { console.error(err) });
  }

async getUsers() {
    this.setState({users:[]});
    await db.collection("users").get()
    .then(res => {
        res.forEach(doc => {
  
            let newItem = doc.data();
            newItem.email = doc.id;
            this.addUser(newItem)
  
        });
    })
    .catch(err => { console.error(err) });
  }

openPatient(item) {
  this.setState({modalTherapist:true})
  this.setState({therapist:item})
}

renderTherapist(item)  {

  return(
          <div className="card shadow m-2 " onClick={() => this.openPatient(item)}>
          <div className="d-flex align-items-center">


            <div className=" col-9 p-2">
            <div className="d-flex">
            <div className="thumbnailCard algin-item-center ">
          
            <img className="rounded-circle" src={item.img || "https://static.vecteezy.com/system/resources/thumbnails/009/734/564/small/default-avatar-profile-icon-of-social-media-user-vector.jpg"} width={48} />
              
          </div>
            <div className="p-2">
            <h4 className="mb-0">{item?.name || item?.email}</h4>
            <a className="link">{item?.email}</a>
            </div>
            </div>
            </div>
         
            <div className=" col-2" >
        
            <Button variant="outline-primary" text="" className="" onClick={() => this.openPatient(item)}>
            Edit { item?.check == false ?  <label title="+1" className="badge pill bg-primary">+1</label> : null }
            </Button>

            </div>
           

            
            </div>         
        </div>
  )

}

renderTransaction(item)  {

  return(
    <div className="card   shadow m-2 " >
    <div className="d-flex  align-items-center ">

      <div className="thumbnailCard m-2">
        <img className="rounded-circle" src={item.user?.img ||  "https://static.vecteezy.com/system/resources/thumbnails/009/734/564/small/default-avatar-profile-icon-of-social-media-user-vector.jpg"} width={48} />
        
      </div>
      <div className="mt-1">
      <h5 className=" mb-0 "><b>{item.name}</b>  </h5>
      <h4 className="badge bg-dark ">{item.address}</h4>
    
      </div>
      <div className="mt-1 mx-5 ">
      <h5 className="badge bg-primary mt-1 ">{item.ammount}€</h5>
      
     
      </div>
      
      <a className="btn btn-primary-soft mx-1" href={`https://dashboard.stripe.com/payments/${item.id}`} target="__blank">See on stripe</a>
      
      </div>         
  </div>
  )

}

renderUser(item)  {

    return(
      <div className="card   shadow m-2 ">
      <div className="d-flex  align-items-center ">
  
        <div className="thumbnailCard m-2">
          <img className="rounded-circle" src={item.img ||  "https://static.vecteezy.com/system/resources/thumbnails/009/734/564/small/default-avatar-profile-icon-of-social-media-user-vector.jpg"} width={48} />
          
        </div>
        <div className="">
        <h5 className=" mb-0 mt-1"><b>{item.email}</b>  </h5>
        </div>
        <div className="mx-2 mt-1">
          <label>Creation date  {new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit'}).format(item.creationDate)}</label>
         
        </div>
      
       { item.therapistLog == true ? <span class="badge badge-pill badge-success">Therapist</span> : <></>}
  
        
        <div className="mt-1">

        </div>
        <div  className="mt-1">
        <div className="btn btn-light cursor m-3" onClick={() => this.openPatient(this.state.sesionData?.user)} >
            <label className="cursor"><i className="bi bi-eye fa-eye"></i> See account</label>
        </div>
        </div>

     
        </div>         
    </div>
    )
  
  }



renderUsers =  () => {

    var data = this.state.users;

    if(data?.length > 0) {
      return(data.map(item => this.renderUser(item)) )
    } else {
      return(
        <>
        <div className="text-center justify-content-center">
          <img src={NotFound} className="img-fluid" width={"200px"}></img>
          <h3 className="text-center">There is not users</h3>
        </div>
        </>
      )
    }
   
  }


  renderTransactions =  () => {

    var data = this.state.transactions;

    if(data?.length > 0) {
      return(data.map(item => this.renderTransaction(item)) )
    } else {
      return(
        <>
        <div className="text-center justify-content-center">
          <img src={NotFound} className="img-fluid" width={"200px"}></img>
          <h3 className="text-center">There is not any transactions</h3>
        </div>
        </>
      )
    }
   
  }



  renderTherapists =  () => {

  var data = this.state.therapists;


  if(data?.length > 0) {
    return(data.map(item => this.renderTherapist(item)) )
  } else {
    return(
      <>
      <div className="text-center justify-content-center">
        <img src={NotFound} className="img-fluid" width={"200px"}></img>
        <h3 className="text-center">Theres is not any therapist register </h3>
      </div>
      </>
    )
  }
 
}

renderWithdraws() {
    var data = this.state.withdraws;


    if(data?.length > 0) {
      return(data.map(item => this.renderTherapist(item)) )
    } else {
      return(
        <>
        <div className="text-center justify-content-center">
          <img src={NotFound} className="img-fluid" width={"200px"}></img>
          <h3 className="text-center">Theres no withdraws yet</h3>
        </div>
        </>
      )
    }
}


  cancelarSesion() {

  }
 
  openChat(user) {

    this.setState({chatUser: user})

    const array = [auth.currentUser?.email,user?.email]
    array.sort();
    var id = array[0] + array[1];
    db.collection("therapists").doc(auth.currentUser?.email).collection("patients").doc(user?.email).set({check:true}, {merge:true})
    this.setState({openChat:true,chatId: id})


    // this.getPatients()
  }

  renderModalSesion() {
    return(

      <Modal show={this.state.modalSesion == true} className="center" fullscreen={"sm-down"}  id="modalCentered"  size={"lg"} centered>
        <ModalHeader className="modal-header"  >
          <h5 className="modal-title">Sesion reservada</h5>
          <button className="btn-close" type="button" onClick={() => this.setState({modalSesion:false})} />
        </ModalHeader>
        <ModalBody className="">

      <div className="card card-body  shadow   mt-1">
      <div className="d-flex d-md-block d-lg-flex align-items-start 4 mt-1">
        
        <img className="rounded-circle" src="https://static.vecteezy.com/system/resources/thumbnails/009/734/564/small/default-avatar-profile-icon-of-social-media-user-vector.jpg" width={48} />
        <div className="pt-md-2 pt-lg-0 ps-3 ps-md-0 ps-lg-3 mt-0">
       
        <h5 className=" mb-2 ">{format(Date(this.state.sesionData?.day), "PP")}    </h5> 
        <span className="badge badge-pill bg-dark mb-0 ">{this.state.sesionData?.hour} </span> 
          <h4 className=" mb-2 ">Sesion con {this.state.sesionData?.user?.name} </h4> 
          Email contacto: <a className=" mb-0 ">{this.state.sesionData?.user?.email} </a> 
          { this.state.sesionData?.contactNumber ? <a className=" mb-0 ">Numero contacto {this.state.sesionData?.nameP} </a> : null}



          
          </div>
      </div>

    </div>
        

       <div className="btn btn-danger m-3   cursor" onClick={() => this.cancelarSesion(this.state.sesionData?.id)} >
            <label className=" cursor">Cancelar sesión</label>
        </div>
        <div className="btn btn-light cursor m-3" onClick={() => this.openPatient(this.state.sesionData?.user)} >
            <label className="cursor"><i className="bi bi-chat fa-comments-dots"></i> Chatear con paciente</label>
        </div>
     
        </ModalBody>


        <Modal.Footer>

        <Button variant="secondary" onClick={() => this.setState({modalSesion: false,})}>
          Cerrar
        </Button>
      </Modal.Footer>
      </Modal>
      
    )
}


  async updateUser() {
    if(auth.currentUser) {
      await db.collection('therapists').doc(auth.currentUser?.email).update(this.state).then(() => {
        toast.success("Perfil actualizado correctemente")
      })
    } else {
      toast.error("Error: Intentelo mas tarde y asegure que esta inciaido sesión debidamente")
    }
  }

  deleteAccount(email) {
            db.collection('therapists').doc(email).then(() => {
        toast.success("Account delete from theraphya")

    })
  }


  renderProfile(){
    if(!this.state.isTherapist) {
      return (
        <>
        	<section className="pb-0 ">
		    	<div className=""
		    	style={{
		    	height:'480px',
		    	backgroundImage: 'url(https://images.unsplash.com/photo-1551847677-dc82d764e1eb?q=80&w=1740&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D)', 
		    	backgroundPosition: 'center left', backgroundSize: 'cover', backgroundOpacity:0.2}}
		    	>
		    	<div className=" container" >
					<div className="container p-3">
						<h1 className="lh-base mb-0 mt-5 text-white">Admin access</h1>
            <h4 className="text-white">You have to be authorised to access this section</h4>

        
            <br/>
            <br/>
            <Link to="/login" className="btn btn-light" type="dark" color="light" >
            Login
            </Link>
					</div>
				</div>
				</div>

	  	</section>
    </>
      )
    } else {
      return (
        <>
        <div className="h-400 d-block d-lg-none d-md-none d-flex justify-content-center align-items-center  ">
            <h3>Use your PC to enter the Admin pannel</h3>
        </div>
       <main className="mt-4 mb-5 d-none d-lg-block d-md-block">
      {/* Modal retire more  */}
      {this.renderModalSesion()}

      <Modal show={this.state.modalTherapist == true} className="center" fullscreen={"sm-down"}  id="modalCentered"  size={"lg"} centered>
        <ModalHeader className="modal-header"  >
          <h5 className="modal-title">Therapist profile</h5>
          <button className="btn-close" type="button" onClick={() => this.setState({modalTherapist:false})} />
        </ModalHeader>
        <ModalBody className="">

      <div className="card card-body  shadow   mt-1">
      <div className="d-flex d-md-block d-lg-flex align-items-start 4 mt-1">
        
        <img className="rounded-circle" src={this.state.therapist?.img || "https://static.vecteezy.com/system/resources/thumbnails/009/734/564/small/default-avatar-profile-icon-of-social-media-user-vector.jpg"} width={48} />
        <div className="pt-md-2 pt-lg-0 ps-3 ps-md-0 ps-lg-3 mt-0">
        {/* <span className="badge badge-pill bg-dark mb-0 ">{this.state.therapi
            st?.} </span>  */}
        <h4 className=" mb-0 ">{this.state.therapist?.email} </h4> 

          <h4 className=" mb-2 ">{this.state.therapist?.name} </h4> 
          <div class="row">
            <div class="col card">
                <span class="h6 font-semibold  text-sm d-block mb-2 mt-2">Account balance</span> 
                <span class="h3 font-bold mb-0">{this.state.therapist?.balance || 0}€ </span>
            </div>

            {this.state.therapist?.description !== undefined ? <div className="card col-12 mt-2 ">
              <p className="mt-2">{this.state.therapist?.description}</p>
          </div>
            : <b> No description</b>}
        </div>

         
          
          <br/>      <br/>
          <h5>Documents</h5>

          ID document file: { this.state.therapist?.proofOfID ? <a className=" mb-0 " href={this.state.therapist?.proofOfID} target="__blank" >See document </a>  : <a>No document</a> } 

          <br/>
          <br/>

          Proof of banking: { this.state.therapist?.proofOfBanking ? <a className=" mb-0 " href={this.state.therapist?.proofOfBanking}target="__blank" >See document </a>   : <a>No document</a> } 
          <br/>
          <br/>


          Proof of address:{ this.state.therapist?.proofOfAddress ?  <a className=" mb-0 " href={this.state.therapist?.proofOfAddress}target="__blank" >See document </a>   : <a>No document</a> } 
          <br/>
          <br/>


          Therapist certification:{ this.state.therapist?.therapistCertification ?  <a className=" mb-0 " href={this.state.therapist?.therapistCertification}target="__blank" >See document </a>  : <a>No document</a> }  
          <br/>


          <div>
        <br/>
        <div className="col-12">
        <b>Percentaje of comision</b>
        <input className="form-control" type="number" onClick={() => this.setState({ percentaje: this.state.therapist?.percentaje})} value={this.state.therapist?.percentaje}></input>


        </div>
     
        </div>
        </div>
      </div>

    </div>
        

       <div className="btn btn-danger m-3   cursor" onClick={() => this.deleteAccount(this.state.therapist?.email)} >
            <label className=" cursor">Ban therapist account </label>
        </div>

       
        </ModalBody>


        <Modal.Footer>

        <Button variant="secondary" onClick={() => this.setState({modalTherapist: false,})}>
          Save
        </Button>
      </Modal.Footer>
      </Modal>

      { this.state.openChat ?  
         <Chat
            therapist={true}
            show={this.state.openChat} 
            close={() => this.setState({openChat:false})} 
            chatUser={this.state.chatUser} 
            id={this.state.chatId} /> : 
          null}
  
   
      {/* Modal retire money end */}
      {/* Container START */}
      <div className="container mt-5">
        <div className="row mt-3">
        <div className="col-lg-3 col-md-3  p  ">
            {/* Advanced filter responsive toggler START */}
            {/* Divider */}
           
            {/* Advanced filter responsive toggler END */}
            <nav className="navbar navbar-light navbar-expand-lg mx-0 text-light">
              <div className="offcanvas offcanvas-start" tabIndex={-1} id="offcanvasNavbar">

                <div className="offcanvas-header">
                  <button type="button" className="btn-close text-reset ms-auto" data-bs-dismiss="offcanvas" aria-label="Close" />
                </div>

                <div className="offcanvas-body p-0">

                  <div className="card w-100">

                    <div className="card-body">

                      <ul className="nav nav-tabs nav-pills nav-pills-soft flex-column fw-bold gap-2 border-0">
                            
                        <li className="nav-item" data-bs-dismiss="offcanvas">
                          <a className={this.state.tab !== "card" ?"nav-link d-flex mb-0 active ":"nav-link d-flex mb-0 t"} href="#nav-setting-tab-1" data-bs-toggle="tab"><span>Users </span></a>
                        </li>

                        <li className="nav-item" data-bs-dismiss="offcanvas">
                          <a href="#nav-setting-tab-2" data-bs-toggle="tab" className={this.state.tab === "card" ?"nav-link d-flex mb-0 active":"nav-link d-flex mb-0 "}><span>Transactions </span></a>
                        </li>
                        <li className="nav-item" data-bs-dismiss="offcanvas">
                          <a className="nav-link d-flex mb-0" href="#nav-setting-tab-3" data-bs-toggle="tab"> <span> Therapists </span></a>
                        </li>
                        <li className="nav-item" data-bs-dismiss="offcanvas">
                          <a href="#nav-setting-tab-4" data-bs-toggle="tab" className={this.state.tab === "card" ?"nav-link d-flex mb-0 active":"nav-link d-flex mb-0 "}><span>Withdraws </span></a>
                        </li>
                      
                    
                      </ul>

                    </div>


                    <div className="card-footer text-center py-2">
                      <a className="btn btn-link text-danger btn-sm" onClick={() => logout()}>Cerrar sesión</a>
                    </div>
                  </div>

                </div>

              </div>
            </nav>
          </div>
            {/* Main content START */}
            <div className="col-lg-6   vstack ">
            {/* Setting Tab content START */}
            <div className="tab-content py-0 mb-0  p-0  m-0 mt-0 ">
         
         
               <div  className={this.state.tab !== "card" ?"tab-pane show active fade":"tab-pane fade"}  id="nav-setting-tab-1">

              
                <div className="card mb-4">

                  <div className="card-header border-0 pb-0">
                    <h1 className="h5 card-title">Users </h1>
                    <p className="mb-0">All the users and pending therapists</p>
                  </div>

                  <div className="card-body">

                    {this.renderUsers()}

                  </div>

                </div>


            

              </div> 


              <div className="tab-pane fade" id="nav-setting-tab-2">
                {/* Card START */}
                
                <div className="card">
                  {/* Card header START */}
                  <div className="card-header border-0 pb-0 ">
                    <div className="row">
                    <div className="col-12 col-md-6">
                      <h5 className="card-title">Transactions</h5>
                      <p className="mb-3  ">All the transactions </p>
                    </div>

                    </div>
                  </div>
                  </div>

                  <div className="card mt-3">
                  <div className="card-body">
                  {this.renderTransactions()}
                  </div>
                  
                  </div>
              </div>


              <div className="tab-pane fade" id="nav-setting-tab-3">
                {/* Card START */}
                
                <div className="card">
                  {/* Card header START */}
                  <div className="card-header border-0 pb-0 ">
                    <div className="row">
                    <div className="col-12 col-md-6">
                      <h5 className="card-title">Therapists</h5>
                      <p className="mb-3  ">All the therapists</p>
                    </div>

                    </div>
                  </div>
                  </div>

                  <div className="card mt-3">
                  <div className="card-body">
                            {this.renderTherapists()}
                  </div>
                  
                  </div>
              </div>
              
              <div  className="tab-pane fade"  id="nav-setting-tab-4">
                {/* Card START */}
                
                <div className="card">
                  {/* Card header START */}
                  <div className="card-header border-0 pb-0 ">
                    <div className="row">
                    <div className="col-md-6 col-12 col-md-6 mb-3">
                      <h5 className="card-title">Withdraws</h5>
                      <p className="mb-0"></p>
                    </div>

                    </div>
                  
                 
                  </div>
                  <div className="h-200">
                  {this.renderWithdraws()}

                  </div>
                  <br/>
                  <br/>
                  <br/>
                  <br/>
                  <br/>

                </div>

                
              </div>

    

           
            </div>
          </div>
        
        </div> {/* Row END */}
      </div>
    </main>

        </>
      )
    }
  }

  render() {
      return this.renderProfile()
    }
} 
  


export default AccessAdmin
