import { Badge } from "react-bootstrap";

export default function cardTherapist(item,openChat,check) {
    return (
        <div className="card card-body  shadow   mt-1">
        <div className="d-flex d-md-block d-lg-flex align-items-start 4 mt-1"><img className="rounded-circle" src={item.img || "https://static.vecteezy.com/system/resources/thumbnails/009/734/564/small/default-avatar-profile-icon-of-social-media-user-vector.jpg"} width={48} />
          <div className="pt-md-2 pt-lg-0 ps-3 ps-md-0 ps-lg-3 mt-0">
            <h3 className=" mb-0 ">{item.name} </h3>
            <ul className="list-unstyled fs-sm ">
              <li><p className="nav-link fw-normal p-0">{item?.description}</p></li>
            </ul>
            </div>
           
        </div>
        <div className="btn btn-light cursor m-3" onClick={() => openChat()} >
            <label className="cursor"><i className="bi bi-chat fa-comments-dots"></i> Chat with therapist{ check == false ?  <label title="+1" className="badge pill bg-primary">+1</label> : null }</label>
             </div>
         { check == false ? <a className="text-primary bold text-center ">New messages</a> :null }
      </div>
    )
}