import React from "react";


import LogoWhite from "../assets/logo-small.png"

import Compromiso from "../assets/icons/compromiso.png"
import Profesionales from "../assets/icons/profesionales.png"
import TerapiaPersonalizada from "../assets/icons/terapia-personalizada.png"

import Estigmas from "../assets/icons/estigmas.png"
import Igualdad from "../assets/icons/igualdad.png"
import Crisis from "../assets/icons/crisis.png"


import { Link  } from "react-router-dom";

class Landing extends React.Component {

  constructor(props) {
    super(props) 
    this.state = {
      loading:true,
    }
  } 



  render() {

      return (
		<div>
    <section className="pb-0">
        <div className="row mb-3 mb-xl-0 bg"
            style={{
            height:'130px',
            // backgroundImage: 'url(https://images.unsplash.com/photo-1551847677-dc82d764e1eb?q=80&w=1740&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D)', 
            backgroundPosition: 'center left', backgroundSize: 'cover', backgroundOpacity:0.2}}
            >
        <div className=" container" >
            <div className="container text-center">
                <h1 className="lh-base mb-0 mt-5 text-dark italic ">ABOUT US</h1>
            </div>
        </div>
        </div>
    <div className="container">
        {/* Main title */}
    

        {/* About detail START */}
        <div className="row">
            {/* Content */}
            <div className="col-lg-6 pe-lg-5 mt-5">
                <h2>
                    <img src={Compromiso} width="50px" />
                 </h2>
                <h3>Our Commitment</h3>
                <p className="mb-5">Our goal is simple but powerful: we want everyone to find happiness and well-being, regardless of their gender, race, economic or social position</p>

            </div>

            <div className="col-lg-6 pe-3  mt-5 ">
                <h2>
                    <img src={Compromiso} width="50px" />
                 </h2>
                <h3>Comfort and Ease</h3>
                <p className="mb-5">We want your experience to be as comfortable and easy as possible. Access our sessions from the comfort of your home, adapting therapy to your lifestyle</p>

            </div>


            <div className="col-lg-6 pe-3 mt-2">
                <h2>
                    <img src={Profesionales} width="50px" />
                 </h2>
                <h3>Professional Excellence</h3>
                <p className="mb-5">We have the best psychologists, digital tools, and educational content, dedicated to providing you with professional and empathetic support. Our team is here to guide you on your path to well-being</p>

            </div>


            <div className="col-lg-6 pe-3 mt-2">
                <h2>
                    <img src={TerapiaPersonalizada} width="50px" />
                 </h2>
                <h3>Personalized Therapy</h3>
                <p className="mb-5">At BHealth, we recognize that each individual is unique. We offer individualized therapy services, tailored to your personal needs and circumstances</p>

            </div>


            {/* Image */}
            <div className="col-lg-5 mt-7 mt-lg-0">
                <div className="position-relative d-flex justify-content-center">
                    {/* Hero image */}


                    {/* Trustpilot review START */}

                </div>
            </div>
        </div>
        {/* About detail END */}
    </div>	
</section>

<section className="pb-0">
    <div className="container">
        {/* Title */}
        <div className="inner-container text-center mb-4 mb-sm-6">
            <h2 className="mb-0">OUR SOCIAL IMPACT</h2>
        </div>

        {/* Service list START */}
        <div className="row row-cols-1 row-cols-sm-2 row-cols-lg-3 g-4">


            {/* Service item */}
            <div className="col">
                <div className="card card-hover-shadow border  p-4">
                    {/* Card body */}
                    <div className="card-body p-0">
                        {/* Icon */}
                        <img src={Estigmas} width="60px" className="mt-2 mb-3" />
        
                        {/* Content */}
                        <h5 className="mb-3"><a href="#">Eliminate Stigmas</a></h5>
                        <p>We challenge and work to eliminate the stigmas associated with mental health. We believe in a space where every individual feels free to seek support without fear of judgment
                            <br/>
                            <br/>
                            <br/>
                            
                        </p>
                    </div>

                
                </div>
            </div>

            {/* Service item */}
            <div className="col">
                <div className="card card-hover-shadow border  p-4">
                    {/* Card body */}
                    <div className="card-body p-0">
                        {/* Icon */}
                        <img src={Igualdad} width="60px" className="mt-2 mb-3" />
                        {/* Content */}
                        <h5 className="mb-3"><a href="#">Well-being and Equality</a></h5>
                        <p>We strive to provide universal access to emotional well-being. Through our initiatives, we work to make online therapy accessible to everyone, regardless of their location or economic situation</p>
                    </div>

                    {/* Card footer */}
                
                </div>
            </div>

            {/* Service item */}
            <div className="col">
                <div className="card card-hover-shadow border  p-4">
                    {/* Card body */}
                    <div className="card-body p-0">
                        {/* Icon */}
                        <img src={Crisis} width="60px" className="mt-2 mb-3" />
        
                        {/* Content */}
                        <h5 className="mb-3"><a href="#">Source of Support in Crisis</a></h5>
                        <p>In times of crisis, we are here to offer support. Our platform serves as a safe haven where you can find professional help when you need it most
                            <br/>
                            <br/>
                            <br/>

                        </p>
                    </div>

                
                </div>
            </div>
        </div>
        {/* Service list END */}
    </div>	
</section>



<section className="pt-0 pt-lg-2 mb-5">
        <div className="container position-relative">
            {/* SVG decoration START */}
            <figure className="position-absolute top-50 start-50 translate-middle ms-2">
            <svg>
                <path
                className="fill-white opacity-4"
                d="m496 22.999c0 10.493-8.506 18.999-18.999 18.999s-19-8.506-19-18.999 8.507-18.999 19-18.999 18.999 8.506 18.999 18.999z"
                />
                <path
                className="fill-white opacity-4"
                d="m775 102.5c0 5.799-4.701 10.5-10.5 10.5-5.798 0-10.499-4.701-10.499-10.5 0-5.798 4.701-10.499 10.499-10.499 5.799 0 10.5 4.701 10.5 10.499z"
                />
                <path
                className="fill-white opacity-4"
                d="m192 102c0 6.626-5.373 11.999-12 11.999s-11.999-5.373-11.999-11.999c0-6.628 5.372-12 11.999-12s12 5.372 12 12z"
                />
                <path
                className="fill-white opacity-4"
                d="m20.499 10.25c0 5.66-4.589 10.249-10.25 10.249-5.66 0-10.249-4.589-10.249-10.249-0-5.661 4.589-10.25 10.249-10.25 5.661-0 10.25 4.589 10.25 10.25z"
                />
            </svg>
            </figure>
            {/* SVG decoration END */}
            <div className="row mt-5">
            <div className="col-12">
                <div className="bg-info p-4 p-sm-5 rounded-3">
                <div className="row position-relative">
                    {/* Svg decoration */}
                    <figure className="fill-white opacity-1 position-absolute top-50 start-0 translate-middle-y">
                    <svg width="141px" height="141px">
                        <path d="M140.520,70.258 C140.520,109.064 109.062,140.519 70.258,140.519 C31.454,140.519 -0.004,109.064 -0.004,70.258 C-0.004,31.455 31.454,-0.003 70.258,-0.003 C109.062,-0.003 140.520,31.455 140.520,70.258 Z" />
                    </svg>
                    </figure>
                    {/* Action box */}
                    <div className="col-11 mx-auto position-relative">
                    <div className="row align-items-center">
                        {/* Title */}
                        <div className="col-lg-7">
                        <div className="icon-lg round bg-opacity-1 rounded-circle mb-3">
                        <img src={LogoWhite} className="rounded-circle rounded"  width="40px" />
                    </div>
                            <h3 className="text-white">We make it easy for you</h3>
                            <p className="text-white mb-3 mb-lg-0">
                            Subscribe to <b>BHealth</b> to get the best price for your sessions
                            <br/>

                            The best price for you
                            </p>
                        </div>
                        {/* Content and input */}
                        <div className="col-lg-5 text-lg-end">
                        <Link to="/precios" className="btn btn-secondary mb-0">
                            See prices 
                        </Link>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            </div>
        </div>
    </section>

</div>
      
      );

    }
  }


export default Landing;
