import React, { useEffect, useState } from "react";

import { useAuthState } from "react-firebase-hooks/auth";

import { Link, useHistory } from "react-router-dom";

import QuestionsImg from "../assets/questions.png"

import {
  auth,
  registerWithEmailAndPassword,
  registerInWithGoogle,
} from "../firebase";

function Register() {


  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [step, setStep] = useState(0);
  const [answers, setAnswers] = useState([]);


  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false)
  const [user, loading] = useAuthState(auth);
  const history = useHistory();

  const [ locationKeys, setLocationKeys ] = useState([])
  const questions = [

  { 
    "button": "Let's Start",
    "title": "Welcome to uTherapy",
    "subtitle": "We will start by asking you some questions about your mental state",
    "img": QuestionsImg
  },
  { 
    "button": "Next",
    "title": "Have you noticed changes in your energy level, motivation, or quality of sleep and rest due to stress?",
    "answers": ["Yes", "Partially", "No"]
  },
  { 
    "button": "Next",
    "title": "Have you observed changes in your behavior or difficulties in concentration as a result of anxiety?",
    "answers": ["Very frequently", "Frequently", "Sometimes", "Never"]
  },
  { 
    "button": "Next",
    "title": "Do you experience sudden mood changes, or episodes of deep sadness and constant irritability?",
    "answers": ["Yes", "No"]
  },
  { 
    "button": "Next",
    "title": "Are there specific aspects of your appearance or personality that cause you concern?",
    "answers": ["Very frequently", "Frequently", "Sometimes", "Never"]
  },
  { 
    "button": "Next",
    "title": "Do you feel exhausted even after resting adequately?",
    "answers": ["Yes", "No"]
  },
  { 
    "button": "Next",
    "title": "Have you experienced situations where you have been a victim of gender-based violence?",
    "answers": ["Yes", "No"]
  },
  { 
    "button": "Next",
    "title": "Have you found yourself in situations where you feel manipulated or emotionally abused?",
    "answers": ["Yes", "No"]
  },
  { 
    "button": "Next",
    "title": "Have you experienced situations of verbal, physical, or online harassment?",
    "answers": ["Yes", "No"]
  },
  { 
    "button": "Next",
    "title": "Have you had thoughts about harming yourself or about suicide? How frequently and intensely?",
    "answers": ["Yes", "No"]
  },
  { 
    "button": "Next",
    "title": "Have you ever experienced persistent anxiety linked to distressing memories?",
    "answers": ["Yes", "No"]
  },
  { 
    "button": "Next",
    "title": "Do you feel insecure and uncomfortable with yourself?",
    "answers": ["Yes", "No"]
  },
  { 
    "button": "Next",
    "title": "Have you ever felt that your gender identity or sexual orientation has been a reason for discrimination, harassment, or lack of understanding?",
    "answers": ["Yes", "No"]
  },
  { 
    "button": "Next",
    "title": "Are there external gender expectations that cause you discomfort or anxiety?",
    "answers": ["Yes", "No"]
  },
  { 
    "button": "Next",
    "title": "Have you noticed behavior patterns in those close to you that make you feel undervalued or disrespected?",
    "answers": ["Yes", "No"]
  },
  { 
    "button": "Next",
    "title": "Are there events in your past that you consider traumatic and that have had a negative impact on your life?",
    "answers": ["Yes", "No"]
  },
  { 
    "button": "Next",
    "title": "Have you experienced significant changes in your sleep pattern, such as insomnia or constant fatigue?",
    "answers": ["Yes", "No"]
  },
  { 
    "button": "Next",
    "title": "Do you experience uncontrollable obsessions that condition your behavior? Do you feel compelled to perform repetitive behaviors in response to your obsessions?",
    "answers": ["Yes", "No"]
  },
  { 
    "button": "Next",
    "title": "Have you experienced or are you experiencing eating patterns that deviate from what is considered normative, such as extreme restrictions or episodes of compulsive eating?",
    "answers": ["Yes", "No"]
  },
  { 
    "button": "Next",
    "title": "Have you ever felt significant discomfort or dissatisfaction with your own body?",
    "answers": ["Yes", "No"]
  },
  { 
    "button": "Next",
    "title": "Do you feel that your relationship with friends, loved ones, or a partner does not reach the quality it should?",
    "answers": ["Yes", "No"]
  },
  { 
    "button": "Next",
    "title": "Are there habits you would like to change but find it difficult? Do you find yourself making decisions that you know are harmful to you?",
    "answers": ["Yes", "No"]
  },
  { 
    "button": "Next",
    "title": "Have you felt or do you feel the emotional and physical burden of dealing with a chronic illness?",
    "answers": ["Yes", "No"]
  },
  { 
    "button": "Next",
    "title": "Do you experience the feeling that life is unfair and meaningless?",
    "answers": ["Yes", "No"]
  },
  { 
    "button": "Next",
    "title": "Do you feel unmotivated or lack purposes or goals that you are passionate about?",
    "answers": ["Yes", "No"]
  },
  { 
    "button": "Next",
    "title": "What is your name?",
    "subtitle": "Finally, write your name",
    "functionA": (e) => setName(e),
    "value": name,
    "input": "Your name",
    "type": "input"
  }
  ]

  const register = () => {
    registerWithEmailAndPassword(email.toLowerCase(), password,name,answers);
  };  

  useEffect(() => {
    return history.listen(location => {
      if (history.action === 'PUSH') {
        setLocationKeys([ location.key ])
      }
  
      if (history.action === 'POP') {
        if (locationKeys[1] === location.key) {
          setLocationKeys(([ _, ...keys ]) => keys)
  
          setStep(++step)
  
        } else {
          setLocationKeys((keys) => [ location.key, ...keys ])
  
          setStep(--step)
  
        }
      }
    })
  }, [ locationKeys, ])


  function LoadUser() {

    if (loading) {
      return;
    }
    if (user) { history.replace("/cuenta")  } 
  }

  useEffect(() => {
    LoadUser()
  }, [user, loading]);


  function RegisterForm()    {
      return (
            <div className="RegisterForm slide-in-blurred-left ">
                <div className="container p-2">
                <h2 className="mb-0">Lastly create your account
                </h2>
                <p className="mb-0">Start your path in BHealth</p>

                {/* Social buttons */}
                          
                {/* Form START */}
                <form>
                  {/* Email */}
                  <div className="input-floating-label form-floating mb-4">
                    <input type="email"
                           className="form-control mt-5"
                           id="floatingInput"
                           placeholder="name@example.com"
                           value={email}
                          onChange={(e) => setEmail(e.target.value)}
                    />
                    <label htmlFor="floatingInput">Email address</label>
                  </div>

                  {/* Password */}
                  <div className="input-floating-label form-floating position-relative">
                    <input 
                        className="form-control fakepassword pe-6" id="psw-input"
                        placeholder="Enter your password"
                        value={password}
                         type={
                            showPassword ? "text" : "password"
                         }
                        onChange={(e) => setPassword(e.target.value)}
                        />
                    <label htmlFor="floatingInput">Password</label>
                    <span className="position-absolute top-50 end-0 translate-middle-y p-0 me-2">
                      <i className="fakepasswordicon fas fa-eye-slash cursor-pointer p-2" 
                      onClick={() =>
                          setShowPassword(!showPassword)
                      }
                      ></i>
                    </span>
                  </div>
                  {/* Pswmeter */}

                  <div className="mb-4 mt-3">
                    <div className="form-check">
                      <input type="checkbox" className="form-check-input" id="checkbox-1"/>
                      <label className="form-check-label" htmlFor="checkbox-1" ><Link> Acepts terms and conditions</Link> y <a href="https://theraphya.com/terms" target="__blank">políticas de privacidad </a></label>
                    </div>
                  </div>
                  {/* Button */}
                  <div className="align-items-center mt-0">
                    <div className="d-grid">
                      <button className="btn btn-primary mb-0" type="button" onClick={() => register()}>Create account</button>
                    </div>
                  </div>
                </form>

                  <div id="pswmeter" className="mt-3"></div>
                  
            
                  <div className="position-relative my-5">
                    <hr/>
                    <p className="small position-absolute top-50 start-50 translate-middle bg-body px-4">Or</p>
                  </div>

                      <div className="row mt-2 mb-3">
                  {/* Social btn */}
                  <div className="col-xl-12 d-grid">
                    <a onClick={() => registerInWithGoogle(answers,name)} className="btn border-primary text-primary bg-white mb-2 mb-xxl-0"><i className="fab fa-fw fa-google text-google-icon me-2"></i>Login witg google</a>
                  </div>
                  
                  </div>
                  </div>
                </div>
        )
  }


  function nextQuestion(titleQ,AnswQ) {

    setStep(step+1)
    setAnswers([
      ...answers,
      { title: titleQ , answer: AnswQ || "" }
    ]);
  
    // console.log(answers)
  }


  function questionCard(item) {
    return (
        <div  className="m-3  mt-0 slide-in-blurred-left ">
            <h2 className="mb-0">{item?.title}</h2>
            <p className="mb-0">{item?.subtitle}</p>
            
            {item?.img != undefined ? <img src={item.img} className="img-fluid" /> :null}
            
            {item?.type == "input" ? 

            <>
                   <div className="input-floating-label form-floating mb-1 mt-0">
                    <input type="string"
                           className="form-control mt-3"
                           id="floatingInput"
                           placeholder="Name "
                           value={item.value}
                           onChange={(e) => item?.functionA(e.target.value)}
                    />
                    <label htmlFor="floatingInput">{item?.input}</label>
                  </div>
            </>
            : null}

            <div className="row mt-4">
            { 
              item?.answers?.map((itemAns, idx) =>
                <button key={idx} type="button" className="btn btn-outline-primary btn-lg m-1 p-2 shadow-sm" onClick={() =>nextQuestion(item.title,itemAns)}>{itemAns}</button>
              )
            }
             </div>
           
             <div className="align-items-center mt-0 justify-content-bottom mt-5">
                  <div className="d-grid">
                    <button className="btn btn-primary mb-0" onClick={() =>nextQuestion(item.title,"")} type="button" >{item?.button}</button>
                    { step > 1 ? <a className="link mt-1 cursor" onClick={ () => setStep(step-1)}><i className="fa fa-back-arrow text-primary"></i> Go back</a> : null}
                  </div>
              </div>
        </div>

      )

  }

  function renderCards() {
        return questionCard(questions[step]);
  }

  return(
      <div className="">
      <main>
        <div className="row g-0">
          {/* left */}
          <div className="col-sm-10 col-lg-5 d-flex m-auto vh-100">
            <div className="row w-100 m-auto">
              <div className="col-lg-10 my-2 m-auto">

              <div className="RegisterForm">
               
             
         
              {   step >= questions.length ? RegisterForm() : renderCards()}

                                 
                </div>

      
                {/* Sign IN link */}
                <div className="mt-4 text-center">
                  <span>¿Ya tienes una cuenta? <Link to="/login"> Log in </Link></span>
                </div>
                
              </div>
            </div>
          </div>

           <div className="col-lg-7 mt-4" >
            {/* Slider START */}
            <div className="vh-100  roundImg "  style={{backgroundImage: 'url(https://images.unsplash.com/photo-1551847677-dc82d764e1eb?q=80&w=1740&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D)', backgroundPosition: 'center left', backgroundSize: 'cover', backgroundOpacity:0.2}}>
            <div className="d-flex flex-column justify-content-center h-100 container">
                    
              <div className="p-3">
              <h1 className="text-light italic">If you want to go fast go alone, if you want to go far go accompanied</h1>

              <div className="d-flex justify-content-between mt-5">
                <div>
                  <h5 className="mb-0 fs-2 text-light ">- African prover</h5>
                </div>

             
              </div>
            </div>
              </div>

            </div>
            </div>
              </div>
    </main>

    </div>

  )
}


export default Register;
