export default function Preguntas() {
    return (
      <section>
        <div className="container align-items-center">
          <h2 className="fs-1 mb-2 text-center">Frequently Asked Questions</h2>
          <p className="mb-4 text-center">
            Access our catalog of experts to start your journey towards personal improvement.
          </p>
          <div className="d-flex justify-content-center align-items-center">
            <div className="accordion col-md-10 text-left" id="accordionExample">
      
              <div className="accordion-item shadow m-4 text-left">
                <h2 className="accordion-header" id="headingOne">
                  <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">What is BHealth and how does it work?</button>
                </h2>
                <div className="accordion-collapse collapse show text-left" aria-labelledby="headingOne" data-bs-parent="#accordionExample" id="collapseOne">
                  <div className="accordion-body text-dark text-left">BHealth is your digital home for emotional well-being. We connect individuals with expert therapists through online therapy sessions. Simply sign up, choose your therapist, and begin your journey towards well-being.</div>
                </div>
              </div>
      
              <div className="accordion-item shadow m-4">
                <h2 className="accordion-header" id="headingTwo">
                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">How do I know if online therapy is right for me?</button>
                </h2>
                <div className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample" id="collapseTwo">
                  <div className="accordion-body text-dark">Online therapy is a convenient option for many people. If you seek comfort, flexibility, and privacy, BHealth could be the ideal choice. Talk to our team to learn more and find out if it is right for you.</div>
                </div>
              </div>
      
              <div className="accordion-item shadow m-4 border-rounded">
                <h2 className="accordion-header" id="headingThree">
                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                  What makes therapy at BHealth unique?</button>
                </h2>
                <div className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample" id="collapseThree">
                  <div className="accordion-body text-dark">At BHealth, we offer a personalized, convenient, and accessible service. Our inclusive approach ensures that everyone, regardless of gender, race, or social status, can seek happiness and well-being.</div>
                </div>
              </div>
      
              <div className="accordion-item shadow m-4 border-rounded">
                <h2 className="accordion-header" id="headingThree">
                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse4" aria-expanded="false" aria-controls="collapse4">
                  How do I choose my therapist?</button>
                </h2>
                <div className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample" id="collapse4">
                  <div className="accordion-body text-dark text-left">You can explore therapist profiles on our platform and choose the one that best fits your needs and preferences. We also offer personalized advice to help you make the right decision.</div>
                </div>
              </div>
      
              <div className="accordion-item shadow m-4 border-rounded">
                <h2 className="accordion-header" id="headingThree">
                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse5" aria-expanded="false" aria-controls="collapseThree">
                  How is my privacy protected?</button>
                </h2>
                <div className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#collapse5" id="collapse5">
                  <div className="accordion-body text-dark">Your privacy is our priority. We use secure technologies and encryption processes to protect your information. All sessions are confidential, and we adhere to the highest ethical standards.</div>
                </div>
              </div>
      
              <div className="accordion-item shadow m-4 border-rounded">
                <h2 className="accordion-header" id="headingThree">
                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse6" aria-expanded="false" aria-controls="collapseThree">
                  Can I access BHealth from anywhere?</button>
                </h2>
                <div className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#collapse6" id="collapse6">
                  <div className="accordion-body text-dark">Yes, you can access BHealth from anywhere with an internet connection. Our platform is designed to provide you with flexibility and convenience.</div>
                </div>
              </div>
      
              <div className="accordion-item shadow m-4 border-rounded">
                <h2 className="accordion-header" id="headingThree">
                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse7" aria-expanded="false" aria-controls="collapseThree">
                  What additional resources does BHealth offer?</button>
                </h2>
                <div className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#collapse7" id="collapse7">
                  <div className="accordion-body text-dark">In addition to therapy sessions, we offer a variety of carefully designed resources, such as interactive tools and educational content, to complement your experience and support your personal growth.</div>
                </div>
              </div>
      
              <div className="accordion-item shadow m-4 border-rounded">
                <h2 className="accordion-header" id="headingThree">
                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse8" aria-expanded="false" aria-controls="collapseThree">
                  How can I start my journey with BHealth?</button>
                </h2>
                <div className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#collapse8" id="collapse8">
                  <div className="accordion-body text-dark">Sign up on our platform, choose your therapist, and start your journey towards a fuller and happier life. We are here to support you every step of the way.</div>
                </div>
              </div>
            </div>
          </div>
      
          <div className="text-center">
            <a href="mailto:info@BHealththerapy.com">Need help? <span className="cursor text-decoration-underline">Contact us here</span></a>
          </div>
        </div>
      </section>
)
} 