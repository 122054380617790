import React from "react";

import {  auth, db, logout } from "../firebase";
import SelectImage from "../Components/SelectImage";

import {  Modal, ModalBody, Button, ModalHeader } from 'react-bootstrap';

import NotFound from "../assets/notfound.png"

import 'react-day-picker/dist/style.css';
import { format } from 'date-fns';


import toast from 'react-hot-toast';
import Swal from "sweetalert2";


class NewTherapist extends React.Component {

  constructor(props) {
    super(props) 
    this.state = {
       
    }

  } 
  
  componentDidMount() {

  }



  async getUser() {

    if(auth.currentUser) {
  
    } 
    else {
      this.setState({isTherapist: false})

  }
};



renderPatients =  () => {

  var data = this.state.patientsList;


  if(data?.length > 0) {
    return(data.map(item => this.renderPatient(item)) )
  } else {
    return(
      <>
      <div className="text-center justify-content-center">
        <img src={NotFound} className="img-fluid" width={"200px"}></img>
        <h3 className="text-center">You do not have any patient assigned</h3>
      </div>
      </>
    )
  }
 
}


  cancelarSesion() {

  }
 
  openChat(user) {

    this.setState({chatUser: user})

    const array = [auth.currentUser?.email,user?.email]
    array.sort();
    var id = array[0] + array[1];
    db.collection("therapists").doc(auth.currentUser?.email).collection("patients").doc(user?.email).set({check:true}, {merge:true})
    this.setState({openChat:true,chatId: id})


    this.getPatients()
  }

  renderModalSesion() {
    return(

      <Modal show={this.state.modalSesion == true} className="center" fullscreen={"sm-down"}  id="modalCentered"  size={"lg"} centered>
        <ModalHeader className="modal-header"  >
          <h5 className="modal-title">Sesion booked</h5>
          <button className="btn-close" type="button" onClick={() => this.setState({modalSesion:false})} />
        </ModalHeader>
        <ModalBody className="">

      <div className="card card-body  shadow   mt-1">
      <div className="d-flex d-md-block d-lg-flex align-items-start 4 mt-1">
        
        <img className="rounded-circle" src="https://static.vecteezy.com/system/resources/thumbnails/009/734/564/small/default-avatar-profile-icon-of-social-media-user-vector.jpg" width={48} />
        <div className="pt-md-2 pt-lg-0 ps-3 ps-md-0 ps-lg-3 mt-0">
       
        <h5 className=" mb-2 ">{format(Date(this.state.sesionData?.day), "PP")}    </h5> 
        <span className="badge badge-pill bg-dark mb-0 ">{this.state.sesionData?.hour} </span> 
          <h4 className=" mb-2 ">Sesion con {this.state.sesionData?.user?.name} </h4> 
          Email: <a className=" mb-0 ">{this.state.sesionData?.user?.email} </a> 
          { this.state.sesionData?.contactNumber ? <a className=" mb-0 "> Contacto number {this.state.sesionData?.nameP} </a> : null}



          
          </div>
      </div>

    </div>
        

       <div className="btn btn-danger m-3   cursor" onClick={() => this.cancelarSesion(this.state.sesionData?.id)} >
            <label className=" cursor">Cancel sesion</label>
        </div>
        <div className="btn btn-light cursor m-3" onClick={() => this.openChat(this.state.sesionData?.user)} >
            <label className="cursor"><i className="bi bi-chat fa-comments-dots"></i> Chat with client</label>
        </div>
     
        </ModalBody>


        <Modal.Footer>

        <Button variant="secondary" onClick={() => this.setState({modalSesion: false,})}>
          Close
        </Button>
      </Modal.Footer>
      </Modal>
      
    )
}


  async updateUser() {
    Swal.fire({
      title: "Sended successfully, we will get back to you on email",
      text:"We will get back to you to continue the verification via email",
      icon:"success"
      }).then(() => {
        window.location.href = "/"

      })
  }


  renderProfile(){
   
      return (
        <>
       <main className="mt-4 mb-5">

      <div className="container mt-5">
        <div className="row mt-3 d-flex align-items-center justify-content-center">
        <div className="col-lg-8 col-md-8   ">
              {/* Account settings START */}
              
              <div className="card mb-4">
                  {/* Title START */}
                  <div className="card-header border-0 pb-0">
                    <h1 className="h5 card-title">Therapist form</h1>
                    <p className="mb-0">Add all your information to become a therapist on our platform</p>
                  </div>
                  {/* Card header START */}
                  {/* Card body START */}
                  <div className="card-body">
                  <div className="">
                    <div className="rounded-top"  />
                    {/* Card body START */}

                      <div className="    pb-0  mt-0">
                              <div className="d-flex d-md-block d-lg-flex align-items-start pt-lg-2 mb-4 mt-2">
                                
                              <div className="avatar avatar-xl mt-2 mb-3 ">
                                  <a>
                                  <img className="avatar-img rounded-circle border border-white border-3 bg-dark" src={this.state.img} alt="" />
                                  </a>
                                
                                  <SelectImage ComponentRender={() => <>
                                    <a className="icon-md btn btn-primary rounded-circle iconProfile rounded circle" >
                                      <i className="bi bi-camera fs-6"> </i>
                                    </a>
                                  </>} onDone={(newItem) => this.setState({img:newItem})}/>
                                  
                                </div>
                                <div className="mt-2 mx-4">
                                  <h3 className=" mb-0 ">{this.state.name || auth.currentUser?.displayName} </h3>
                                  <ul className="list-unstyled fs-sm mt-3 mb-0">
                                    <li><a className="nav-link fw-normal p-0"><i className="fas-calendar opacity-60 " />Select an image for your profile</a></li>

                                  </ul>
                                  
                            
                                  </div>
                              </div>


                          <br/>

                      


                      </div>
                    </div>


                    {/* Form settings START */}
                    <div className="row g-3 ">
                      {/* First name */}
                      <div className="col-sm-12 col-lg-12">
                        <label className="form-label">Full name</label>
                        <input   value={this.state.name} onChange={(e) => this.setState({name:e.target.value})} type="text" className="form-control"    />
                      </div>
                    
                      <div className="col-sm-12 col-lg-12">
                        <label className="form-label">Email</label>
                        <input   value={this.state.email} onChange={(e) => this.setState({email:e.target.value})} type="text" className="form-control"    />
                      </div>
                   

                      <div className="col-12">
                        <label className="form-label">About your / certifications</label>
                        <textarea  className="form-control" value={this.state.description} onChange={(e) => this.setState({description:e.target.value})} rows={4} />
                        <small>Character limit: 300</small>
                      </div>

                      <div className="col-sm-6 col-lg-6">
                        <label className="form-label">Passport or National ID </label>
                        <input   value={this.state.id} onChange={(e) => this.setState({dni:e.target.value})} type="text" className="form-control"    />
                      </div>

                      <label className="form-label">To verify your profile you need to add your oficial document in pdf or image</label>
                      <SelectImage ComponentRender={() => <>
                                    <a className=" btn btn-secondary-soft " >
                                      <i className="bi bi-file-earmark-person-fill fs-6"> </i> Upload National Document
                                    </a>
                      </>} onDone={(newItem) => this.setState({proofOfID:newItem})}/>
                      <small>{this.state.proofOfID}</small>


                      <label className="form-label">Upload and document with your banking details at your name</label>
                      <SelectImage ComponentRender={() => <>
                                    <a className=" btn btn-secondary-soft " >
                                      <i className="bi bi-file-earmark-person-fill fs-6"> </i> Proof of banking 
                                    </a>
                      </>} onDone={(newItem) => this.setState({proofOfBanking:newItem})}/>
                      <small>{this.state.proofOfBanking}</small>


                      <label className="form-label">Add a home invoice or similiar with your address to proof your address</label>
                      <SelectImage ComponentRender={() => <>
                                    <a className=" btn btn-secondary-soft " >
                                      <i className="bi bi-file-earmark-person-fill fs-6"> </i> Proof of address 
                                    </a>
                      </>} onDone={(newItem) => this.setState({proofOfAddress:newItem})}/>
                      <small>{this.state.proofOfAddress}</small>



                      <label className="form-label">Therapist certification or diploma</label>
                      <SelectImage ComponentRender={() => <>
                                    <a className=" btn btn-secondary-soft " >
                                      <i className="bi bi-file-earmark-person-fill fs-6"> </i> Therapist certifications
                                    </a>
                      </>} onDone={(newItem) => this.setState({therapistCertification:newItem})}/>
                      <small>{this.state.therapistCertification}</small>




                      {/* Button  */}
                      <div className="col-12 text-end">
                        <button  className="btn btn-lg btn-primary mb-0" onClick={() => this.updateUser()}>Send information</button>
                      </div>
                    </div>
                    {/* Settings END */}
                  </div>
                  {/* Card body END */}
                </div>
        
      </div>
      </div>
      </div>

      {/* Container END */}
    </main>

        </>
      )

  }

  render() {
      return this.renderProfile()
    }
}


export default NewTherapist;
