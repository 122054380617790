import React from "react";

import {  auth, db } from "../firebase";

import {  Modal, ModalBody, Button, ModalHeader } from 'react-bootstrap';

import NotFound from "../assets/notfound.png"

import Swal from 'sweetalert2'
import toast from 'react-hot-toast';

import { DayPicker } from 'react-day-picker';
import 'react-day-picker/dist/style.css';
import { format } from 'date-fns';

import cardTherapist from "../Components/CardTherapist";
import { checkChatExists } from "../Components/Helpers";



import Chat from "./Chat";
import Settings from "./ProfileTherapist";



class Profile extends React.Component {



  constructor(props) {
    
    super(props) 
    this.state = {
        tab:1,
        loading:true,
        sesionsAmmount: 0,  
        selectedDay: null,
        selectedHour: null,
        subscribe: false,
        check:true, 
        therapistLog:false,
        sesions: [],  
        hours:[
          {hour:"9:00", reserved: false},
          {hour:"10:00", reserved: false},
          {hour:"11:00", reserved: false},
          {hour:"12:00", reserved: false},
          {hour:"13:00", reserved: false},
          {hour:"14:00", reserved: false},
          {hour:"15:00", reserved: false},
          {hour:"16:00", reserved: false},
          {hour:"17:00", reserved: false},
          {hour:"18:00", reserved: false},
          {hour:"19:00", reserved: false},
          {hour:"20:00", reserved: false},
        ],
       
    }
  }

  resetHours() {
    this.setState({
      hours: [
          {hour:"9:00", reserved: false},
          {hour:"10:00", reserved: false},
          {hour:"11:00", reserved: false},
          {hour:"12:00", reserved: false},
          {hour:"13:00", reserved: false},
          {hour:"14:00", reserved: false},
          {hour:"15:00", reserved: false},
          {hour:"16:00", reserved: false},
          {hour:"17:00", reserved: false},
          {hour:"18:00", reserved: false},
          {hour:"19:00", reserved: false},
          {hour:"20:00", reserved: false},]
    })
  }

  componentDidMount() {
    const query = new URLSearchParams(this.props.location.search);
    const sp = query.get('successpay')
        if(sp == 'true') {
          this.sucessPayment()
        }
    setTimeout(() => {
        this.getUser()
    }, 2000);

  }

  sucessPayment() {
    Swal.fire({
      title: "You have suscribed correctly",
      text:"You have activated your BHealth subscription, you will now have a weekly session available with your therapist",
      icon:"success"
      })
  }

  sucessReservation() {
    Swal.fire({
      title: "Sesion booked",
      text:"You have successfully booked your session. Your therapist will contact you to confirm the session",
      icon:"success"
      })
  }

  changeT() {
    this.setState({modalChange: false})
      Swal.fire({
      title: "The change has been requested",
      text:"A change of therapist has been requested, a new one will be assigned to you shortly..",
      icon:"success"
      })
  }

  selectDayLoad() {
    if(this.state.therapistData?.name !== undefined) {
    this.resetHours()  
    var s = new Date(this.state.selectedDay).toLocaleDateString("en-US")
    s = s.replace("/", "-")
    s = s.replace("/", "-")

    // load that day 
    db.collection("therapists").doc(this.state.therapist).collection(s).get()
      .then(res => {
          res.forEach(doc => {

              let id = doc.id;
              var myArray = this.state.hours;
              var objIndex = myArray.findIndex(obj => obj.hour == id);

              //Update object's name property.
              myArray[objIndex].reserved =  true
              this.setState({hours:myArray})
          });
      })
      this.setState({tab:2})
    } else {
      toast.error("You don't have an asign therapist. You can't book a sesion yet");
    }
  }

  async bookSesion() {
    if(this.state.sesionsAmmount > 0) {
    let id = "id-" + Date.now();

    var s = new Date(this.state.selectedDay).toLocaleDateString("en-US")
   

    var data = {
      day: this.state.selectedDay,
      hour: this.state.selectedHour || false,
      bookDate: id,
      user: { email: auth.currentUser?.email , name: this.state.name },
      therapistData: this.state.therapistData,
      dayDisplay: s,
    }


    s = s.replace("/", "-")
    s = s.replace("/", "-")


    this.setState({loading:true})
    await db.collection("users").doc(auth.currentUser?.email).collection('sesions').doc(id).set(data).then(() => {
     

      db.collection("therapists").doc(this.state.therapist).collection(s).doc(this.state.selectedHour).set(data)


      db.collection("therapists").doc(this.state.therapist).collection("sesions").add(data)


      db.collection("users").doc(auth.currentUser?.email).update({sesionsAmmount: this.state.sesionsAmmount - 1})

      this.setState({
        modalOpen: false,
        selectedDay: null,
        selectedHour: null,
        status:"reserved",
        tab:1,
      })
      this.resetHours()
      this.sucessReservation()
      this.getSesions()
      setTimeout(() =>  {
      this.getUser()
          }, 1000)
        })
      }
        else {
          toast.error("Buy BHealth sessions or subscribe");
        }
  }

  logrosfun() {
    toast.success("Do more sessions to unlock achievements in your progress");
  }

  openSesion() {
    this.setState({modalSesion:true})

  }

renderSesion(item)  {

  return(
          <div className="card   shadow m-2 " onClick={() => this.openSesion(item)}>
          <div className="d-flex  align-items-center ">

       {/* "     <div className="thumbnailCard m-2">
              <img className="rounded-circle" src={item.therapistData?.img ||  "https://static.vecteezy.com/system/resources/thumbnails/009/734/564/small/default-avatar-profile-icon-of-social-media-user-vector.jpg"} width={48} />
              
            </div>" */}

            <div className="avatar avatar-xl mt-2 mb-3 ">
        <a>
        <img className="avatar-img rounded-circle border border-white border-3 bg-dark" src={item.therapistData?.img ||  "https://static.vecteezy.com/system/resources/thumbnails/009/734/564/small/default-avatar-profile-icon-of-social-media-user-vector.jpg"} alt="" />
        </a>

          </div>
            <div className="">
            <h5 className=" mb-0 mt-1">Sesion with <b>{this.state.therapistData?.name}</b> booked </h5>
            <h5>{item.dayDisplay}</h5>
            </div>
            <div className="">
             <h4 className="badge bg-dark m-3">{item.hour}</h4>
              
            </div>
            <Button variant="outline-primary" text="" className="mx-4">
              Check details
            </Button>


            
            </div>         
        </div>
  )

}

renderSesions =  () => {

    var data = this.state.sesions;

    if(data.length > 0) {
      return(data.map(item => this.renderSesion(item)) )
    } else {
      return(
        <>
        <div className="text-center justify-content-center">
          <img src={NotFound} className="img-fluid" width={"200px"}></img>
          <h3 className="text-center">You don't have any sesions </h3>
        </div>
        </>
      )
    }
   
  }

  getUser = async () => { 
      if(auth.currentUser) {
        await db.collection('users').doc(auth.currentUser?.email).get()
        .then(doc => {
            const data = doc.data();

            this.setState(data)
            this.setState({sesionsAmmount: data?.sesionsAmmount|| 0})
    
            if(data?.subscribe == true) {
              this.setState({ subscribe: true})
                
            }  else {
              this.setState({ subscribe: false})
            }
            
            if(data?.therapistLog !== true) {
    
              checkChatExists(auth.currentUser?.email, data?.name,data?.therapist)

              

              db.collection("therapists").doc(data?.therapist).get().then(docT => {
                var d = docT.data()
  
                const array = [auth.currentUser.email,data?.therapist]
                array.sort();
                var id = array[0] + array[1];
                this.setState({chatId: id})

                d.email = data?.therapist;
                this.setState({ therapistData:d})

                db.collection("users").doc(auth.currentUser?.email).collection("chats").doc(id).get().then((doc) =>
                {
                  if(doc.exists){
                  var chatDoc = doc.data()
                  this.setState({check: chatDoc.check})
                  }
                }
                )

              })
               
            } else {
            }
        
        })
          this.getSesions()
          this.setState({loaded: true})

        } else {
            window.location.href ="/register"
        }
}

handleChangeHour = (event) => {
    this.setState({selectedHour: event.target.value});
  }

addS(newItem) {
    this.setState({ sesions: [...this.state.sesions, newItem] })
  }


async getSesions() {
      this.setState({sesions:[]});
      await db.collection("users").doc(auth.currentUser?.email).collection('sesions').get()
      .then(res => {
          res.forEach(doc => {

              let newItem = doc.data();
              this.addS(newItem)

          });
      })
      .catch(err => { console.error(err) });
  } 

 

  renderDay(){

    if (this.state.selectedDay !== null) {
       return(<p>Has seleccionado {format(this.state.selectedDay, 'PP')}.</p>);
    } else {
        return( <p>Select day for next sesion.</p>)
    }
  }
  renderHours() {
    var data = this.state.hours;
    return data.map(item => 
         (
              <>
              <div className="m-2">
              {item.reserved == false ?
                <Button variant={this.state.selectedHour == item.hour ? "success" : "light"} size="lg"  onClick={() => this.setState({selectedHour:item.hour})}>
                    {item.hour}
                </Button>
              : 
                <Button variant={"outline-danger"}  size="lg" outline="true" onClick={() => this.setState({alertReserved: true})}>
                    {item.hour}
                </Button>
              }
              </div>
              </>
          )
    )
    
  }

    renderModalChange() {
      return(

        <Modal show={this.state.modalChange == true} className="center" fullscreen={"sm-down"}  id="modalCentered"  size={"lg"} centered>
          <ModalHeader className="modal-header"  >
            <h5 className="modal-title">Reason of change</h5>
            <button className="btn-close" type="button" onClick={() => this.setState({modalChange:false})} />
         
          </ModalHeader>
          <ModalBody className="">
          

         <div className="btn btn-light m-3   cursor" onClick={() => this.changeT()} >
              <label className=" p-1">Don't like the therapist</label>
          </div>


         <div className="btn btn-light m-3   cursor" onClick={() => this.changeT()} >
              <label className=" p-1">I don't feel good</label>
          </div>

          <div className="btn btn-light m-3  cursor" onClick={() => this.changeT()} >
              <label className=" p-1">Other</label>
          </div>





          </ModalBody>

          <Modal.Footer>

          <Button variant="secondary" onClick={() => this.setState({modalChange: false,})}>
            Close
          </Button>
        </Modal.Footer>
        </Modal>
        
      )
  }

  renderModalSesion() {
    return(

      <Modal show={this.state.modalSesion == true} className="center" fullscreen={"sm-down"}  id="modalCentered"  size={"lg"} centered>
        <ModalHeader className="modal-header"  >
          <h5 className="modal-title">Sesion booked</h5>
          <button className="btn-close" type="button" onClick={() => this.setState({modalSesion:false})} />
        </ModalHeader>
        <ModalBody className="">
        {this.cardTherapistLoad()}
        

       <div className="btn btn-danger m-3   cursor" onClick={() => this.changeT()} >
            <label className=" cursor">Cancel sesion</label>
        </div>


       <div className="btn btn-light cursor m-3" onClick={() => this.setState({openChat:true})} >
            <label className="cursor"><i className="bi bi-chat fa-comments-dots"></i> Chat with therapist  </label>
        
        </div>
     




        </ModalBody>

        <Modal.Footer>

        <Button variant="secondary" onClick={() => this.setState({modalSesion: false,})}>
          Cerrar
        </Button>
      </Modal.Footer>
      </Modal>
      
    )
}

  renderModalSesions() {
      return(

        <Modal show={this.state.modalSesions == true} className="modal center" fullscreen={"sm-down"}  id="modalCentered"  size={"lg"} centered>
          <ModalHeader className="modal-header"  >
            <h5 className="modal-title">Buy more sesions</h5>
            <button className="btn-close" type="button" onClick={() => this.setState({modalSesions:false})} />
         
          </ModalHeader>
          <ModalBody className="modal-body">
          <div className="container">
            
          <h4 className="mt-2 mb-2">Packs de sesiones</h4>

          <div className="card shadow">

            <div className="card-body d-flex">
              <div>
              <span className="badge bg-success mb-2">Deal</span>
              <h5 className="card-title">Buy 10 sesions</h5>
              <p className="card-text">Buy 10 sesions that would be add to your account and you can used them at any moment</p>
              <h4>350€</h4>
              
              <a href="https://buy.stripe.com/7sIg1beX83VTeqYeUV" target="_blank" className="btn btn-primary">Buy</a>
              </div>
            
            </div>
          </div>


          <div className="card mt-2 shadow">
            <div className="card-body">
             <h5 className="card-title">Buy 20 sesions</h5>
              <p className="card-text">Buy 20 sesions that would be add to your account and you can used them at any moment</p>
               <h4>600€</h4>
              <a href="https://buy.stripe.com/eVaeX7eX8cspgz6cMO" target="_blank" className="btn btn-primary">Buy now</a>
            </div>
          </div>

            </div>
          </ModalBody>

          <Modal.Footer>

          <Button variant="secondary" onClick={() => this.setState({modalSesions: false,})}>
            Close
          </Button>
        </Modal.Footer>
        </Modal>
        
      )
  }
  cerrarBtn() {
    this.setState({modalOpen: false, selectedDay: null, selectedHour: null, tab:1})
    this.resetHours();
  }

  Volver() {
    this.setState({tab:1})
    this.resetHours();

  }


  openChatFun() {
    this.setState({openChat:true, check:true})
    db.collection("users").doc(auth.currentUser.email).collection("chats").doc(this.state.chatId).set({check:true}, {merge:true})

  }


  cardTherapistLoad() {
    if(this.state.therapistData?.name !== undefined){
    
    return (
      <>
      {cardTherapist(this.state.therapistData,() => this.openChatFun(),this.state.check)}
     
       <div onClick={() => this.setState({modalOpen: false, modalChange: true}) } className="mt-2  link text-danger underline  cursor">
          <a>Change therapist</a>
         </div>
      </>
    )} else {
      return (
        <div className="h-200">
          <a>You have not yet been assigned a therapist, one will be assigned to you shortly.</a>
        </div>
      )
    }

  }
  renderProfile() {
    return (
      <main>
      <div>
        {/* Page content*/}
      <div className="container   mt-5 ">
      {this.renderModalSesions()}
      {this.renderModalSesion()}
      {this.renderModalChange()}

      
      { this.state.openChat ?  
         <Chat 
            show={this.state.openChat} 
            close={() => this.setState({openChat:false})} 
            chatUser={this.state.therapistData} 
            id={this.state.chatId} /> : 
          null}
      <Modal show={this.state.modalOpen} className="modal center"  id="modalCentered"   centered>
          <ModalHeader className="modal-header"  >
            <h5 className="modal-title">Book your next sesions  </h5>
            
          </ModalHeader>
          <ModalBody className="modal-body">
        
            
           {this.state.sesionsAmmount  <= 0 ? 
           <>
            You don't have any sesions left<br/>
           </>
            :
            <>
               You have <b> {this.state.sesionsAmmount || 0 } </b> sesions left 
            </>
            }
         
            <div className="row">
              <div className=" justify-content-center align-items-center">
                
                { this.state.tab == 1 ? <div>
                  <DayPicker
                    mode="single"
                    dis
                    selected={ this.state.selectedDay}
                    onSelect={(va) => this.setState({selectedDay:va })}
                    footer={this.renderDay()}
                  />
                </div>
                : 
              <div>
              {this.renderDay()}
              Select hour for sesion
              <div className="form-group">

              <div className="d-flex flex-wrap  justify-content-center">
              {this.renderHours()}

              {this.state.alertReserved ? <label className="text-danger">Time not available. If you want another therapist available at that time, click <a className="f-bold text-decoration-underline underline text-danger cursor ">here</a></label> : null}
              

              </div>

            
              </div>

              </div>
                }

              </div>

         
            


            </div>
          </ModalBody>

          <Modal.Footer>
          

          <Button variant="secondary" onClick={() => this.cerrarBtn()}>
            Close
          </Button>

            { this.state.selectedHour == null ?
            <>
           
             {this.state.tab == 1 && this.state.selectedHour == null ?
               <Button variant="primary" onClick={() => this.selectDayLoad()}>
                  Next
              </Button> 
              :
              <Button variant="primary" onClick={() => this.Volver()}>
                Go back
              </Button> 
              }
            </>
            : 
             <Button variant="primary" onClick={() => this.bookSesion()}>
               Book sesion
              </Button> 
            }
            
 

        </Modal.Footer>
      
    </Modal>

          {/* Page content*/}
          <div className="row">
            {/* Sidebar*/}
            <aside className="col-lg-4 col-md-5 pe-xl-4 mb-5">
              {/* Account nav*/}
              <div className="card card-body  shadow   pb-1  mt-3">
                <div className="d-flex d-md-block d-lg-flex align-items-start pt-lg-2 mb-4 mt-2"><img className="rounded-circle" src="https://static.vecteezy.com/system/resources/thumbnails/009/734/564/small/default-avatar-profile-icon-of-social-media-user-vector.jpg" width={48} />
                  <div className="pt-md-2 pt-lg-0 ps-3 ps-md-0 ps-lg-3 mt-2">
                    <h3 className=" mb-0 ">Your account</h3>
                    <ul className="list-unstyled fs-sm mt-3 mb-0">
                    <li><a className="nav-link p-0 bold"><i className="fa fa-profile  " />{this.state.name ||auth.currentUser?.displayName}</a></li>
                    <li><a className="nav-link fw-normal p-0"><i className="fas-calendar opacity-60 " />{auth.currentUser?.email}</a></li>
                    <li><a className="nav-link fw-normal p-0"><i className="fas-calendar opacity-60 " />User since  {auth.currentUser?.metadata?.creationTime?.slice(0,16)}</a></li>
                    </ul>
                    
                  <h3 className=" mt-2 ">Awards</h3>

                    <div className="d-flex  align-items-center" onClick={() => this.logrosfun()}>

                      <img src="../assets/logros/suscribe.png" onClick={() => this.logrosfun()}  className="img-fluid mx-1" width={'25px'}/>
                      <img src="../assets/logros/1-sesion-grey.png"   onClick={() => this.logrosfun()} className="img-fluid mx-1" width={'25px'}/>
                      <img src="../assets/logros/meditation-grey.png" onClick={() => this.logrosfun()}  className="img-fluid mx-1" width={'25px'}/>
                      <img src="../assets/logros/mind-grey.png"  onClick={() => this.logrosfun()} className="img-fluid mx-1" width={'25px'}/>

                    </div>
                    </div>
                </div>

                <a className="btn btn-primary  btn-lg w-100 mb-3 bold text-white"  onClick={() => this.setState({modalOpen: true})}><i className="fi-plus me-2" />Book a sesion</a>
              
              <button type="button" className="btn btn-outline-secondary" onClick={() => this.setState({modalSesions: true})}>
              Sesiones <span className="badge bg-success ms-1">{this.state.sesionsAmmount}</span>
            </button>
            <br/>
              </div>
                {this.state.subscribe == false ? 
                <a     style={{textDecoration: 'none'}} className="list d-flex justify-content-between align-items-center  shadow  p-2 border-3 rounded mt-3 bg-primary  text-white ">
                 <div className="card-body ">
                      <div className="row mt-3">
                          <div className="col-12">
                              <h4 className="card-text text-white">Plan suscription</h4>
                              <p>With BHealth's PRO plan you have access to 4 monthly sessions</p>
                              <a href="https://buy.stripe.com/00g2albKW8c90A85ks" className="btn btn-light">Suscribe</a>
 
                          </div>
                          

                         
                      </div>
                    

                  </div>  
                  </a>
                     : 
                    null
              } 
            </aside>
            {/* Content*/}
            <div className="col-lg-8 col-md-7 mb-5 mt-2 pt-2">
            {this.cardTherapistLoad()}
            <p className="pt-2 mt-2">Your next sesions</p>
            

            {this.renderSesions()}


            </div>
            
          </div>
        </div>
      </div>

      </main>
    )
  }

  render() {
    if(this.state.therapistLog !== true) {
      return this.renderProfile()
    } else {
      return <Settings></Settings>
    }
  }

 }


  export default Profile;